/* Reset and Global Styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

body {
  background-color: #f4f4f9;
  color: #333;
  font-size: 16px;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

/* Navbar Styling */
.navbar {
  background-color: #2c3e50;
  padding: 10px;
  display: flex;
  justify-content: center;
}

.navbar a {
  color: white;
  padding: 14px 20px;
  text-decoration: none;
  font-size: 18px;
  margin: 0 10px;
  transition: background 0.3s ease;
}

.navbar a:hover {
  background-color: #34495e;
  border-radius: 4px;
}

.navbar a.active {
  background-color: #e67e22;
  border-radius: 4px;
}

/* Main container */
h1 {
  text-align: center;
  margin-top: 20px;
  font-size: 2.5rem;
  color: #3f72af;
  text-transform: uppercase;
  letter-spacing: 2px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 20px auto;
  max-width: 800px;
}

ul li {
  background-color: #ffffff;
  margin: 10px 0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

ul li:hover {
  transform: translateY(-5px);
}

ul li a {
  text-decoration: none;
  font-size: 1.2rem;
  color: #112d4e;
  transition: color 0.3s ease;
}

ul li a:hover {
  color: #f9a825;
}

/* Footer */
footer {
  text-align: center;
  padding: 20px;
  background-color: #3f72af;
  color: white;
  position: fixed;
  width: 100%;
  bottom: 0;
}

footer p {
  margin: 0;
}

/* Responsive Styling */
@media (max-width: 768px) {
  nav ul {
    flex-direction: column;
    gap: 10px;
  }

  h1 {
    font-size: 2rem;
  }

  ul li {
    padding: 15px;
  }

  ul li a {
    font-size: 1rem;
  }
}
