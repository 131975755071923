/* Container Styling */
.einstellungen-container {
    max-width: 500px;
    margin: 50px auto;
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .formContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .formContainer label {
    font-size: 1.1rem;
    color: #333;
    display: block;
    width: 100%; /* Ensure labels take full width */
    max-width: 400px; /* Align with input fields and dropdown */
    margin: 0 auto 5px auto; /* Center labels and add spacing below */
    text-align: left; /* Align label text to the left */
}
  
  .form-group label {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 5px;
  }
  
  /* Ensure the input field and dropdown have the same width */
  .input-field,
  .dropdown {
    width: 100%;
    max-width: 400px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 0 auto;
  }

  .input-field {
    padding: 10px;
  }
  
  /* Submit Button */
  .submit-btn {
    width: 50%;
    background-color: #2c3e50;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease;
    margin: 0 auto;
    margin-top: 20px;
  }
  
  .submit-btn:hover {
    background-color: #34495e;
  }

  .error-message {
    color: red;
    text-align: left; /* Align label text to the left */
    display: block;
    width: 100%; /* Ensure labels take full width */
    max-width: 400px; /* Align with input fields and dropdown */
    margin: 0 auto 5px auto; /* Center labels and add spacing below */
    margin-top: 5px;
  }

  .success-message {
    visibility: hidden; /* Initially hidden */
    transition: visibility 0.3s ease-in-out; /* Smooth transition */
    text-align: left; /* Align label text to the left */
    margin: 0 auto 5px auto; /* Center labels and add spacing below */
    color: green;
    width: 100%; /* Ensure labels take full width */
    max-width: 400px; /* Align with input fields and dropdown */
    margin: 0 auto 5px auto; /* Center labels and add spacing below */
    margin-top: 5px;
  }
  
  .success-message.visible {
    visibility: visible;
  }
  
  .success-message.hidden {
    visibility: hidden;
  }
  
  